#popup-notification {

    @include w($xs) {
        &.modal-overlay{
            &.open {
                overflow-y: scroll;
                .modal-window {
                    transform: translateY(50px);
                }
            }
        }

    }
}